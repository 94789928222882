<template>
  <div class="message-list">
    <h1>收到的消息</h1>

    <!-- 搜索框 -->
    <input v-model="searchKeyword" placeholder="搜索关键词" />

    <!-- 排序选择 -->
    <select v-model="sortOption">
      <option value="time">按时间排序</option>
      <option value="sender">按发送者排序</option>
    </select>

    <!-- 分页 -->
    <div>
      <button @click="previousPage">上一页</button>
      <span>{{ currentPage }} / {{ totalPages }}</span>
      <button @click="nextPage">下一页</button>
    </div>

    <!-- 筛选已读/未读 -->
    <div>
      <label>
        <input type="radio" v-model="filterOption" value="all" />全部
      </label>
      <label>
        <input type="radio" v-model="filterOption" value="read" />已读
      </label>
      <label>
        <input type="radio" v-model="filterOption" value="unread" />未读
      </label>
    </div>

    <ul>
      <li v-for="message in displayedMessages" :key="message.id" class="message-item">
        <div class="message-header">
          <img :src="message.avatar" class="avatar" alt="头像" />
          <div class="sender-info">
            <span class="sender-name">{{ message.sender }}</span>
            <span class="message-time">{{ formatDate(message.time) }}</span>
          </div>
        </div>
        <div class="message-content">{{ message.content }}</div>

        <!-- 标记为已读按钮 -->
        <button v-if="!message.read" @click="markAsRead(message.id)">标记为已读</button>

        <!-- 删除消息按钮 -->
        <button @click="deleteMessage(message.id)">删除消息</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
    data() {
        return {
            messages: [
                {
                    id: 1,
                    sender: '小明',
                    avatar: 'https://img1.baidu.com/it/u=3451038560,181462407&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
                    time: '2023-09-10 09:30:00',
                    content: '你好，这是一条测试消息。',
                    read: false,
                },
                {
                    id: 2,
                    sender: '小红',
                    avatar: 'https://img0.baidu.com/it/u=1861891325,1582914636&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
                    time: '2023-09-11 14:45:00',
                    content: '明天开会的时间有变动，请注意。',
                    read: true,
                },
                // 更多消息...
            ],
            searchKeyword: '', // 搜索关键词
            sortOption: 'time', // 排序选项
            currentPage: 1, // 当前页码
            pageSize: 5, // 每页显示数量
            filterOption: 'all', // 筛选选项
        }
    },
    computed: {
    // 根据搜索关键词、筛选选项和当前页码计算展示的消息列表
        displayedMessages() {
            let filteredMessages = this.messages

            // 根据搜索关键词过滤消息列表
            if (this.searchKeyword) {
                // eslint-disable-next-line max-len
                filteredMessages = filteredMessages.filter(message => message.content.toLowerCase().includes(this.searchKeyword.toLowerCase()))
            }

            // 根据筛选选项过滤消息列表
            if (this.filterOption === 'read') {
                filteredMessages = filteredMessages.filter(message => message.read)
            } else if (this.filterOption === 'unread') {
                filteredMessages = filteredMessages.filter(message => !message.read)
            }

            // 根据排序选项排序消息列表
            if (this.sortOption === 'time') {
                filteredMessages.sort((a, b) => new Date(b.time) - new Date(a.time))
            } else if (this.sortOption === 'sender') {
                filteredMessages.sort((a, b) => a.sender.localeCompare(b.sender))
            }

            // 根据当前页码和每页显示数量计算当前页显示的消息列表
            const start = (this.currentPage - 1) * this.pageSize
            const end = start + this.pageSize
            return filteredMessages.slice(start, end)
        },
        totalPages() {
            return Math.ceil(this.displayedMessages.length / this.pageSize)
        },
    },
    methods: {
        formatDate(dateTime) {
            const dt = new Date(dateTime)
            return `${dt.getFullYear()}-${this.padZero(dt.getMonth() + 1)}-${this.padZero(
                dt.getDate()
            )} ${this.padZero(dt.getHours())}:${this.padZero(dt.getMinutes())}`
        },
        padZero(number) {
            return number < 10 ? `0${number}` : number
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++
            }
        },
        markAsRead(messageId) {
            const message = this.messages.find(msg => msg.id === messageId)
            if (message) {
                message.read = true
            }
        },
        deleteMessage(messageId) {
            const index = this.messages.findIndex(msg => msg.id === messageId)
            if (index !== -1) {
                this.messages.splice(index, 1)
            }
        },
    },
}
</script>


  <style>
.message-list {
  text-align: center;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

.message-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f5f5;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.sender-info {
  display: flex;
  align-items: center;
}

.sender-name {
  font-weight: bold;
  margin-right: 5px;
}

.message-time {
  color: #888;
}

.message-content {
  color: #666;
  margin-top: 10px;
}
</style>